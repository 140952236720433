import { computed } from 'vue'
import authService from '@/Services/authService'
import { useRoute } from 'vue-router'

export default function useLeague(){
    const { user } = authService()
    const route = useRoute()

    const activeLeague = computed(() => {
		if(route.params.leagueId && user.value.leagues) {
            return user.value.leagues.filter((league) => {
                return league.id === Number(route.params.leagueId)
            })[0]
        }
        return null
	})

    return {
        activeLeague,
    }
}