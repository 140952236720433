import { computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const state = reactive({
    data: null,
    errors: null,
})

export default function pageService() {

    const router = useRouter()
    const data = computed(() => state.data)
    const errors = computed(() => state.errors)

    const setData = function(data){
        state.data = data
    }

    const setErrors = function(errors){
        state.errors = errors
    }

    const fetch = async function(url){
        if(typeof url === 'undefined'){
            url = route(useRoute().name, Object.values(useRoute().params))
        }

        await window.axios.get(url)
            .then((response) => {
                setData(response.data)

                //redirect if necessary
                if(data.value.redirectTo){
                    return { name: data.value.redirectTo, params: data.value.params }
                }
            })
            .catch((errors) => {
                if(errors.response.status === 403) router.push({ name: 'errors.403' })
                else if(errors.response.status === 500) router.push({ name: 'errors.500' })
                else if(errors.response.status === 404) router.push({ name: 'errors.404' })
                setErrors(errors)
            })
    }

    const reset = function(){
        setData(null)
        setErrors(null)
    }

    return {
        data,
        errors,
        fetch,
        reset,
    }
}