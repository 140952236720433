// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { reactive, computed } from 'vue'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = initializeApp({
  apiKey: "AIzaSyAD6NVtVuJeAc0bPf5ClrK_5ExSqdQ1TqY",
  authDomain: "mma-pick-ems-and-fantasy.firebaseapp.com",
  projectId: "mma-pick-ems-and-fantasy",
  storageBucket: "mma-pick-ems-and-fantasy.firebasestorage.app",
  messagingSenderId: "698868354500",
  appId: "1:698868354500:web:e3cf95ebf129243267e891",
  measurementId: "G-HESWY1FEW6"
})

const messaging = getMessaging()

const state = reactive({
  isSupported: isSupported(),
})

export default function useFirebase(){

    const isSupported = computed(() => state.isSupported)

    function fetchToken(registration){
      getToken(messaging, { vapidKey: 'BMKzm2mTXWp5NxzKEcQdZ6-kTu-kdNvKSUGMX_zKWn1nGiAFMrxczdss0OuBTpjO7pijoeXQTjZvzEeUsDTx470', serviceWorkerRegistration: registration }).then((currentToken) => {
        if(currentToken) {
          window.axios.post(route('fcmtokens.store'), { token: currentToken })
            .then(() => {
              //console.log(currentToken)
            })
            .catch(() => {})
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    }

    return {
        fetchToken,
        isSupported,
    }
}