<script setup>

	import { ref, computed, watch } from 'vue';
	import Notifications from '@/Components/Notifications/Notifications.vue';
	import Notification from '@/Components/Notifications/Notification.vue';
	import ChampionCongratulated from '@/Components/Notifications/ChampionCongratulated.vue';
	import GIFReceived from '@/Components/Notifications/GIFReceived.vue';
	import UserWelcomed from '@/Components/Notifications/UserWelcomed.vue';
	import YearInReview2024 from '@/Components/Notifications/YearInReview2024.vue'
	import notificationsStore from '@/Stores/notificationsStore';
	import NavLeague from '@/Components/NavLeague.vue';
	import Footer from '@/Components/Footer.vue';
	import Link from '@/Components/Link.vue';
	import { useRoute, useRouter } from 'vue-router'
	import authService from '@/Services/authService.js'
	import { usePwa } from '@/Composables/usePwa'
	import useLeague from '@/Composables/useLeague'
	import CookieBanner from '@/Components/CookieBanner.vue'
	import useCookieBanner from '@/Composables/useCookieBanner.js'
	import { bootstrap } from 'vue-gtag'

	const props = defineProps({
		theme: {
			type: String,
		},
	});
	const { onlineAndConnected, installEvent } = usePwa()
	const { user, authenticated } = authService()
	const { activeLeague } = useLeague()
	const router = useRouter()
	const vueRoute = useRoute()
	const installDismissed = ref(false)
	
	const { init, cookiesAcceptedAt } = useCookieBanner()
	init()

	//initialize tracking
	if((cookiesAcceptedAt.value && cookiesAcceptedAt.value !== 'rejected') || (authenticated.value && user.value && user.value.cookies_accepted_at)){
		bootstrap().then(() => {
			gtag('event', 'page_view');
		})

		if (typeof(pendo) !== 'undefined' && user.value){
			pendo.initialize({
				visitor: {
					id: user.value.id, // Required if user is logged in
					email: user.value.email, // Recommended if using Pendo Feedback, or NPS Email
					created_at: user.value.created_at
				},
			});
		}
	}

	if(typeof Storage !== 'undefined' && localStorage.getItem('installDismissed') === 'true') installDismissed.value = true
	
	let expandTopNav = ref(false)
	let expandBottomNav = ref(false)
	let notifications = null

	if(vueRoute.params.leagueId){
		notifications = user.value.notifications.filter((notification) => {
			return notification.type === 'App\\Notifications\\UserWelcomed' ||
				notification.data.league_id && notification.data.league_id === Number(vueRoute.params.leagueId)
		});
	}

	notificationsStore.items = notifications

	if(notificationsStore.items && notificationsStore.items.length > 0){
		notificationsStore.show_notifications = true;
	}

	// If createPwa caught the install event then this exposes it for the user to
	// trigger via a button in the UI. This won't work in some browsers - eg Safari.
	let install = function() {
		if (installEvent.value) {
			installEvent.value.prompt()
		}
	}

	let dismissInstall = function() {
		if (typeof(Storage) !== 'undefined') {
			installDismissed.value = true
			localStorage.setItem('installDismissed', 'true');
		}
	}

	let close = function(index) {
		notificationsStore.close(index);
	}

	let closeAllNavigation = function() {
		expandTopNav.value = false;
		expandBottomNav.value = false;
	}

	let updateBottomNavigation = function(value) {
		expandBottomNav.value = value;
	}

	let updateTopNavigation = function(event) {
		if(!expandTopNav.value){
			event.stopPropagation();
			expandTopNav.value = true;
		} else {
			expandTopNav.value = false;
		}
	}

	let logout = function(){
		window.axios.post(route('logout'))
			.then(() => {
				router.push({ name: 'home.index' });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const theme = computed(() => {
		if(props.theme){
			return props.theme
		} else if (user.value.theme)
			return user.value.theme
		else {
			return 'default'
		}
	})

	const isIOS = computed(() => {
		return [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.platform)
		// iPad on iOS 13 detection
		|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
	})

	// Handle going on and offline here. You can do things like write data to local
	// storage when going offline, and upload it back to a server when going online.
	watch(
		() => onlineAndConnected.value,
		(newVal) => {
			if (!newVal) {
				router.push({ name: 'errors.503' })
			} else {
				router.push({ name: 'home.index' })
			}
		}
	)

</script>

<template>

	<div class="mb-24" @click="closeAllNavigation">
		
		<Transition
			appear
			enter-from-class="opacity-0"
			enter-active-class="duration-500"
			leave-active-class="duration-200"
			leave-to-class="opacity-0">
			<Notifications v-if="notificationsStore.show_notifications" :class="theme" @close="close(0)">
				<TransitionGroup
					appear
					enter-from-class="opacity-0 -translate-x-[48%] -translate-y-[53%]"
					enter-active-class="duration-500"
					leave-active-class="duration-500"
					leave-to-class="opacity-0 -translate-x-[48%] -translate-y-[53%]">
					<Notification 
						v-for="(notification, index) in notificationsStore.items" 
						:key="notification.id" 
						v-show="index === 0">
						<ChampionCongratulated 
							v-if="notification.type === 'App\\Notifications\\ChampionCongratulated' && notification.data.my_fighter.fighter"
							:notification="notification"
							:index="index"
							@close="close(index)" />
						<GIFReceived 
							v-if="notification.type === 'App\\Notifications\\GIFReceived'"
							:notification="notification"
							@close="close(index)" />
						<UserWelcomed 
							v-if="activeLeague && notification.type === 'App\\Notifications\\UserWelcomed'"
							:league="activeLeague"
							:notification="notification"
							:index="index"
							@close="close(index)" />
						<YearInReview2024 
							v-if="notification.type === 'App\\Notifications\\YearInReview2024'"
							:notification="notification"
							:index="index"
							@close="close(index)" />
					</Notification>
				</TransitionGroup>
			</Notifications>
		</Transition>

		<NavLeague v-if="activeLeague" :class="theme" :expandBottomNav="expandBottomNav" :league="activeLeague" @update-navigation="updateBottomNavigation" />

		<div :class="theme" class="bg-surface-1 w-full h-full -z-10 fixed"></div>

		<div :class="theme" class="sm:m-auto sm:w-5/6 lg:w-3/5 2xl:w-2/5 relative">
			<CookieBanner v-if="!cookiesAcceptedAt && (!authenticated || (authenticated && user && !user.cookies_accepted_at))" />
			<Link class="bg-surface-3 text-content-3 w-full block text-center text-xs sm:text-sm py-1.5 px-1" v-else-if="activeLeague && activeLeague.live_event" :to="{ name: 'picks.index', params: { leagueId: activeLeague.id, eventId: activeLeague.live_event.id }}">
				<span class="text-red-500">[ Live ]</span> {{ activeLeague.live_event.name }}
			</Link>
			<div v-else-if="installEvent && !isIOS && !installDismissed" class="relative bg-surface-3 text-content-3 w-full block text-center sm:text-lg py-1.5 px-1">
				<a @click.prevent="install" href="#">
					Install MMA Pick'ems and Fantasy <font-awesome-icon :icon="['fas', 'download']" />
				</a>
				<a @click.prevent="dismissInstall" href="#" class="absolute right-2 top-1/2 -translate-y-1/2 text-xl sm:text-2xl">
					<font-awesome-icon :icon="['fas', 'xmark']" />
				</a>
			</div>
		</div>

		<div :class="theme" class="sm:m-auto sm:w-5/6 lg:w-3/5 2xl:w-2/5 relative">
			<div class="absolute w-full top-0 left-0 z-40">
				<div class="flex justify-between items-center px-2 py-1 bg-surface-4">
					<div class="flex items-center">
						<div v-if="new Date().getDate() === 14 && new Date().getMonth() === 8 && new Date().getFullYear() === 2024" class="mr-2 relative flex-none">
							<img src="/images/party-disco-ball.gif" class="w-16" />
							<div class="absolute top-[55%] left-0 w-full -translate-y-1/2 border border-black">
								<div class="border border-y-2 border-[#ec8d00]">
									<div class="border border-y-4 border-[#b32e21]">
										<div class="border border-y-2 border-[#00a06d]">
											<p class="bg-black text-white font-bold text-[10px] text-center leading-tight">
												Noche UFC
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<h1 class="font-sans text-lg font-bold text-content-3 overflow-x-auto">
								<Link :to="user.id ? { name: 'leagues.index' } : { name: 'home.index'}">
									<span v-if="activeLeague" class="whitespace-nowrap">{{ activeLeague.name }}</span>
									<span v-else>MMA Pick'ems</span>
								</Link>
							</h1>
						</div>
					</div>
					<div v-if="user.id" class="justify-self-end">
						<!-- Hamburger -->
						<div class="-mr-2 flex items-center">
							<span class="text-content-3 text-sm" v-if="activeLeague">
								<Link :to="{ name: 'fighter.edit', params: { leagueId: activeLeague.id }}">{{ activeLeague.points }} CR</Link>
							</span>
							<div >
								<button @click="updateTopNavigation($event)" class="inline-flex items-center justify-center p-2 rounded-md text-content-3">
									<svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
										<path :class="{'hidden': expandTopNav, 'inline-flex': ! expandTopNav }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
										<path :class="{'hidden': ! expandTopNav, 'inline-flex': expandTopNav }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
									</svg>
								</button>
							</div>
						</div>
					</div>
					<div v-else class="grow text-right text-content-2 py-1">
						<Link :to="{ name: 'login.create' }" class="text-content-3 inline-block border-surface-1">Log in</Link>
						<span class="mx-1.5 sm:mx-2">/</span>
						<Link :to="{ name: 'register' }" class="text-content-3 inline-block border-surface-1 font-semibold">Sign up</Link>
					</div>
				</div>

				<!-- Responsive Navigation Menu -->
				<div v-if="user" class="col-span-3 bg-accent-1 text-content-2 z-20">
					<div :class="{'block': expandTopNav, 'hidden': ! expandTopNav}">
						<hr class="border-0 border-b border-surface-1 opacity-25 w-11/12 md:w-[95%] m-auto">
						<div class="pt-2 pb-3">
							<Link :to="{ name: 'leagues.index' }" class="pb-1 block pl-4 py-2.5 text-base text-content-2 font-semibold">
								Leagues
							</Link>
							<Link class="block text-base text-content-2 font-semibold pl-9 py-2.5" v-for="league in user.leagues" :to="{ name: 'leagues.upcoming.show', params: { leagueId: league.id }}">
								▸ {{ league.name }}
							</Link>
							<Link class="py-1 block pl-4 py-2.5 text-base text-content-2 font-semibold" :to="{ name: 'leagues.create' }">
								Create League
							</Link>
							<hr class="my-2 border-0 border-b border-surface-1 opacity-25 w-11/12 md:w-[95%] mx-auto">
							<Link :to="{ name: 'users.general.edit' }" class="block pl-4 py-2.5 text-base text-content-2 font-semibold">
								Account Settings
							</Link>
							<Link :to="{ name: 'feedback.create' }" class="block pl-4 py-2.5 text-base text-content-2 font-semibold">
								Submit Feedback
							</Link>
							<a @click.prevent="logout" href="#" class="block pl-4 py-2.5 text-base text-content-2 font-semibold">
								Log Out
							</a>
							<Link v-if="user.isAdmin" :to="{ name: 'admin.index' }" class="block pl-4 py-2.5 text-base text-content-2 font-semibold">
								Admin Options
							</Link>
						</div>
					</div>
				</div>

			</div>
			<main class="p-3 sm:px-0" :class="[ (new Date().getDate() === 14 && new Date().getMonth() === 8 && new Date().getFullYear() === 2024) ? 'pt-24' : 'pt-14' ]">
				<slot />
			</main>
			<Footer v-if="!$route.path.includes('/leagues') && !$route.path.includes('/users') && !$route.path.includes('/admin')" />
		</div>
	</div>
</template>