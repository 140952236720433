<script setup>

    import { computed } from 'vue'

    const props = defineProps({
        notification: Object,
    })

    const underdog = computed(() => {
        return JSON.parse(props.notification.data.most_underdog_pick).filter((fighter) => {
            return fighter.fighterOdds < 0.5
        })[0]
    })

    const favorite = computed(() => {
        return JSON.parse(props.notification.data.most_underdog_pick).filter((fighter) => {
            return fighter.fighterOdds >= 0.5
        })[0]
    })

    const bestEvent = computed(() => (props.notification.data.best_event) ? JSON.parse(props.notification.data.best_event)[0] : null)

</script>

<template>
    <div class="p-2">
        <div class="flex gap-2 items-center mb-2">
            <div class="mb-2 w-2/3 sm:w-3/4 flex-none">
                <h3>The 2024 results are in and...</h3>
                <h2 class="text-2xl"><span class="font-bold">I don't keep up with the joneses</span>, you are a legend.</h2>
            </div>
            <div>
                <img src="/images/yearinreview/pantoja.webp" class="max-w-full" />
            </div>
        </div>
        <ul class="list-disc ml-5 mb-2">
            <li>You participated in <span class="font-bold">{{ notification.data.number_of_events }} events</span>, made <span class="font-bold">{{ notification.data.total_picks }} picks</span>, and won <span class="font-bold">{{ notification.data.number_of_belts }} belts</span> while achieving an overall accuracy of <span class="font-bold">{{ notification.data.pick_accuracy }}%</span>.</li>
            <li v-if="bestEvent">Your best performance happened at <span class="font-bold">{{ bestEvent.eventName }}</span> ({{ bestEvent.eventPoints }}pts).</li>
            <li v-if="props.notification.data.most_underdog_pick">You defied all odds and won when you chose <span class="font-bold">{{ underdog.fighterName }}</span> to beat <span class="font-bold">{{ favorite.fighterName }}</span> at <span class="font-bold">{{ underdog.eventName }}</span>.</li>
            <li>You finished the year ranked <span v-if="notification.data.league_rank"><span class="font-bold">#{{ notification.data.league_rank }}</span> in your league and </span><span class="font-bold">#{{ notification.data.global_rank }}</span> globally (overall).</li>
        </ul>
        <p class="mb-2">Thank you to all <span class="font-bold">{{ notification.data.users_count }} users</span> that competed this year. Here's to more great fights in the New Year 🍻</p>
    </div>
</template>