import { reactive } from 'vue';

export default reactive({

    items: [],
    show_notifications: false,

    add(item) {
        this.items.push(item);
    },

    async close(index) {
        await window.axios.put(route('notifications.update', this.items[index].id))
            .then((res) => {
                this.items.splice(index, 1);
                if(this.items.length === 0){
                    setTimeout(() => {
                        this.show_notifications = false;
                    }, 500);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

});