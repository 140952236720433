<script setup>
    import useCookieBanner from '@/Composables/useCookieBanner.js'
    import authService from '@/Services/authService'

    const { setCookiesAcceptedAt } = useCookieBanner()
    const { setUserCookiesAcceptedAt } = authService()

    const accept = function(){
        let date = new Date()
        setCookiesAcceptedAt(date)
        setUserCookiesAcceptedAt(date)
    }

</script>
<template>
    <div class="bg-surface-3 text-content-3 text-sm gap-4 px-4 py-3 z-[999] sm:flex sm:justify-between sm:items-center">
        <p>We use cookies to enhance your browsing experience and analyse our traffic. By clicking "Accept", you consent to our use of cookies. <router-link :to="{ name: 'privacy' }" class="underline">Privacy Policy</router-link></p>
        <div class="mt-2 sm:mt-0 sm:flex-none space-x-2">
            <button @click.prevent="accept" class="font-semibold p-2 bg-surface-1 text-content-1">Accept</button>
            <button @click.prevent="setCookiesAcceptedAt('rejected')" class="font-semibold p-2">Reject</button>
        </div>
    </div>
</template>