import { computed, reactive } from 'vue'

const state = reactive({
    authenticated: false,
    user: {},
    redirect: null,
})

export default function authService() {
    const authenticated = computed(() => state.authenticated)
    const user = computed(() => state.user)
    const redirect = computed(() => state.redirect)

    const setAuthenticated = (authenticated) => {
        state.authenticated = authenticated
    }

    const setUser = (user) => {
        state.user = user
    }

    const setRedirect = (redirect) => {
        state.redirect = redirect
    }

    const setUserCookiesAcceptedAt = (value) => {
        if(authenticated.value && !user.value.cookies_accepted_at) window.axios.put(route('users.cookies.update'), { accepted_at: value })
    }

    const attempt = async () => {
        try {
            let response = await axios.get(route('auth.user.show'))
            setAuthenticated(true)
            setUser(response.data)
            return response
        } catch (e) {
            setAuthenticated(false)
            setUser({})
        }
    }

    return {
        authenticated,
        user,
        redirect,
        setRedirect,
        attempt,
        setUserCookiesAcceptedAt
    }
}