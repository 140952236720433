<script setup>

    import { computed } from 'vue';
    import { useRouter } from 'vue-router'
    import notificationsStore from '@/Stores/notificationsStore'

    const props = defineProps({
        notification: Object,
        index: Number,
    });

    const emit = defineEmits(['close']);
    const router = useRouter()
    let data = props.notification.data;
    let gifs = [
        'walkout1.gif', 
        'walkout2.gif', 
        'walkout3.gif', 
        'walkout4.gif', 
        'walkout5.gif', 
        'walkout6.gif', 
        'walkout7.gif'
    ];

    let redirect = async function() {
        await notificationsStore.close(props.index);
        if(notificationsStore.items.length === 0){
            notificationsStore.show_notifications = false;
        }
        router.push(leaderboardRoute.value)
    }

    const leaderboardRoute = computed(() => {
        if(data.type === 'App\\Models\\Event'){
            return data.global_champion ? { name: 'leaderboard.global.show', params: { leagueId: data.league_id, eventId: data.competition_id } } : { name: 'leaderboard.show', params: { leagueId: data.league_id, eventId: data.competition_id } }
        }
        return { name: 'fantasy.leaderboards.show', params: { leagueId: data.league_id, fantasySeasonId: data.competition_id } }
    });

    const playersCount = computed(() => {
        return Number(data.participants_count) - Number(data.champions_count);
    });

    const message = computed(() => {

        let message = 'You outpicked ';
        
        if(data.global_champion) {
            message += '<span class="font-semibold">' + playersCount.value + '</span> players';
        } else {
            message += 'your leaguemates';
        }

        message += ' and won the belt at <span class="font-semibold">' + data.competition_name + '</span>.';

        return message;

    });

    const beltFileName = computed(() => {
        if(data.my_fighter.fighter.type === 'default-fighter'){
            return data.my_fighter.fighter.name.substring(0, data.my_fighter.fighter.name.length - 2);
        }
        return data.my_fighter.fighter.name;
    });

</script>

<template>
    <div>
        <h2 class="text-3xl mb-2">Congrats <span class="font-bold">champ.</span></h2>
        <div class="relative bg-black mb-2">
            <img :src="'/images/walkout_gifs/' + gifs[Math.floor(Math.random() * gifs.length)]" class="w-full object-cover opacity-60" />
            <div class="absolute top-0 left-0 w-full h-full text-center">
                <img v-if="data.my_fighter.fighter.type === 'default-fighter'" :src="'/images/fighters/' + data.my_fighter.fighter.name + '.png'" class="inline-block h-full" />
                <img :src="'/images/fighters/' + beltFileName + '_belt.png'" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full" />
            </div>
        </div>
        <p v-html="message"></p>
        <div class="flex items-center justify-center mt-3">
            <a href="#" @click.prevent="redirect" class="font-semibold py-1 px-2 bg-accent-1 text-content-2 inline-block">
                Visit leaderboard
            </a>
            <a href="#" class="py-1 px-2 text-content-1 inline-block" @click="emit('close')">
                Close
            </a>
        </div>
    </div>
</template>