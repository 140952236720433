<script setup>
    
    const props = defineProps({
        notification: Object
    });

    const emit = defineEmits(['close']);

</script>

<template>
    <div>
        <div class="relative">
            <p class="absolute top-0 left-0 bg-surface-1">From: {{ notification.data.from }}</p>
            <img :src="notification.data.gif" class="w-full" />
        </div>
        <div class="text-center mt-4">
            <p v-if="notification.data.message" class="text-xl mb-4">{{ notification.data.message }}</p>
            <a href="#" class="py-1 px-2 bg-accent-1 text-content-2 inline-block" @click="emit('close')">
                Close
            </a>
        </div>
    </div>
</template>