import { createWebHistory, createRouter } from 'vue-router'

import authService from './Services/authService.js';
import pageService from '@/Services/pageService.js'
import { usePwa } from '@/Composables/usePwa'

const HomeIndex = () => import('./Pages/Home/Index.vue')
const BlogIndex = () => import('./Pages/Blog/Index.vue')
const BlogShow = () => import('./Pages/Blog/Show.vue')
const BlogCreate = () => import('./Pages/Blog/Create.vue')
const BlogEdit = () => import('./Pages/Blog/Edit.vue')
const Feedback = () => import('./Pages/Feedback.vue')
const Terms = () => import('./Pages/Terms.vue')
const Privacy = () => import('./Pages/Privacy.vue')
const Login = () => import('./Pages/Auth/Login.vue')
const Register = () => import('./Pages/Auth/Register.vue')
const ForgotPassword = () => import('./Pages/Auth/ForgotPassword.vue')
const ResetPassword = () => import('./Pages/Auth/ResetPassword.vue')
const AuthEmailUnsubscribe = () => import('./Pages/Auth/Unsubscribe.vue')
const AuthGeneralSettingsEdit = () => import('./Pages/Auth/GeneralSettingsEdit.vue')
const AuthPasswordEdit = () => import('./Pages/Auth/PasswordEdit.vue')
const AuthThemeEdit = () => import('./Pages/Auth/ThemeEdit.vue')
const FantasySeasonIndex = () => import('./Pages/Fantasy/Season/Index.vue')
const FantasySeasonCreate = () => import('./Pages/Fantasy/Season/Create.vue')
const FantasySeasonEdit = () => import('./Pages/Fantasy/Season/Edit.vue')
const FantasyDraftShow = () => import('./Pages/Fantasy/Draft/Show.vue')
const FantasyDraftEdit = () => import('./Pages/Fantasy/Draft/Edit.vue')
const FantasyRosterIndex = () => import('./Pages/Fantasy/Roster/Index.vue')
const FantasyTradeIndex = () => import('./Pages/Fantasy/Trade/Index.vue')
const FantasyTradeCreate = () => import('./Pages/Fantasy/Trade/Create.vue')
const FantasyFreeAgentIndex = () => import('./Pages/Fantasy/FreeAgent/Index.vue')
const FantasyActiveFighterIndex = () => import('./Pages/Fantasy/ActiveFighter/Index.vue')
const LeagueIndex = () => import('./Pages/League/Index.vue')
const LeagueCreate = () => import('./Pages/League/Create.vue')
const LeagueEdit = () => import('./Pages/League/Edit.vue')
const LeagueShow = () => import('./Pages/League/Show.vue')
const LeaderboardIndex = () => import('./Pages/Leaderboard/Index.vue')
const LeaderboardLeagueVsLeagueShow = () => import('./Pages/Leaderboard/LeagueVsLeague/Show.vue')
const LeagueUserIndex = () => import('./Pages/League/User/Index.vue')
const LeagueLeaveEdit = () => import('./Pages/League/Leave/Edit.vue')
const InviteCreate = () => import('./Pages/Invite/Create.vue')
const InviteEdit = () => import('./Pages/Invite/Edit.vue')
const PickIndex = () => import('./Pages/Pick/Index.vue')
const MyFighterEdit = () => import('./Pages/MyFighter/Edit.vue')
const SendGIFEdit = () => import('./Pages/SendGIF/Edit.vue')
const AdminIndex = () => import('./Pages/Admin/Index.vue')
const EventCreate = () => import('./Pages/Event/Create.vue')
const EventEdit = () => import('./Pages/Event/Edit.vue')
const AdminUFCStatsCreate = () => import('./Pages/Admin/UFCStats/Create.vue')
const AdminSyncEdit = () => import('./Pages/Admin/Sync/Edit.vue')
const AdminOrphanedFighterIndex = () => import('./Pages/Admin/OrphanedFighter/Index.vue')
const Error403 = () => import('./Pages/Error/403.vue')
const Error500 = () => import('./Pages/Error/500.vue')
const Error404 = () => import('./Pages/Error/404.vue')
const Error503 = () => import('./Pages/Error/503.vue')

const guestOnlyRoutes = [
  'home.index',
  'login.create',
  'register',
  'password.email',
  'password.store',
];

const guestOrAuthRoutes = [
  'blogs.index',
  'blogs.show',
  'terms',
  'privacy',
  'unsubscribe',
  'errors.404',
  'errors.403',
  'errors.500',
  'errors.503',
]

const titleTail = ' - MMA Pick\'ems and Fantasy';

const { authenticated, user, redirect, setRedirect, attempt } = authService()
const { data, fetch } = pageService()

const routes = [
  // {
  //   path: '/test',
  //   component: () => import('./Pages/Test.vue'),
  //   name: 'test',
  //   meta: {
  //     title: 'blagh'
  //   }
  // },
  { 
    path: '/', 
    component: HomeIndex, 
    name: 'home.index',
    meta: {
      title: 'Fun, free, play solo or with friends'
    }
  },
  { 
    path: '/blogs', 
    component: BlogIndex, 
    name: 'blogs.index',
    meta: {
      title: 'Matchup Analyses'
    }
  },
  { 
    path: '/blogs/:blogSlug', 
    component: BlogShow, 
    name: 'blogs.show',
    meta: {
      title: 'Matchup Analysis'
    }
  },
  { 
    path: '/blogs/create', 
    component: BlogCreate, 
    name: 'blogs.create',
    meta: {
      title: 'Create Blog'
    }
  },
  { 
    path: '/blogs/:blogId/edit', 
    component: BlogEdit, 
    name: 'blogs.edit',
    meta: {
      title: 'Edit Matchup Analysis'
    }
  },
  { 
    path: '/feedback', 
    component: Feedback, 
    name: 'feedback.create',
    meta: {
      title: 'Submit Feedback'
    }
  },
  { 
    path: '/terms', 
    component: Terms, 
    name: 'terms',
    meta: {
      title: 'Terms of Service'
    }
  },
  { 
    path: '/privacy', 
    component: Privacy, 
    name: 'privacy',
    meta: {
      title: 'Privacy Policy'
    }
  },
  //Auth
  { 
    path: '/login', 
    component: Login, 
    name: 'login.create',
    meta: {
      title: 'Login'
    }
  },
  { 
    path: '/register', 
    component: Register, 
    name: 'register',
    meta: {
      title: 'Register'
    }
  },
  { 
    path: '/forgot-password', 
    component: ForgotPassword, 
    name: 'password.email',
    meta: {
      title: 'Forgot Password'
    }
  },
  { 
    path: '/password-reset/:token', 
    component: ResetPassword, 
    name: 'password.store',
    meta: {
      title: 'Reset Password'
    }
  },
  { 
    path: '/unsubscribe', 
    component: AuthEmailUnsubscribe, 
    name: 'unsubscribe.show',
    meta: {
      title: 'You have successfully unsubscribed!'
    }
  },
  { 
    path: '/users/general/edit', 
    component: AuthGeneralSettingsEdit, 
    name: 'users.general.edit',
    meta: {
      title: 'General Settings'
    }
  },
  { 
    path: '/users/password/edit', 
    component: AuthPasswordEdit, 
    name: 'users.password.edit',
    meta: {
      title: 'Change Password'
    }
  },
  { 
    path: '/users/theme/edit', 
    component: AuthThemeEdit, 
    name: 'users.theme.edit',
    meta: {
      title: 'Choose Theme'
    }
  },
  //Fantasy Seasons
  { 
    path: '/leagues/:leagueId/fantasy', 
    component: FantasySeasonIndex, 
    name: 'fantasy.seasons.index',
    meta: {
      title: 'Fantasy Seasons'
    }
  },
  { 
    path: '/leagues/:leagueId/fantasy/create', 
    component: FantasySeasonCreate, 
    name: 'fantasy.seasons.create',
    meta: {
      title: 'Create Fantasy Season'
    }
  },
  { 
    path: '/leagues/:leagueId/fantasy/:fantasySeasonId/edit', 
    component: FantasySeasonEdit, 
    name: 'fantasy.seasons.edit',
    meta: {
      title: 'Edit Fantasy Season'
    }
  },
  //Fantasy Drafts
  { 
    path: '/leagues/:leagueId/fantasy/:fantasySeasonId/draft', 
    component: FantasyDraftShow, 
    name: 'fantasy.drafts.show',
    meta: {
      title: 'Draft Lobby'
    }
  },
  { 
    path: '/leagues/:leagueId/fantasy/:fantasySeasonId/draft/edit', 
    component: FantasyDraftEdit, 
    name: 'fantasy.drafts.edit',
    meta: {
      title: 'Draft'
    }
  },
  //Fantasy Rosters
  { 
    path: '/leagues/:leagueId/fantasy/:fantasySeasonId/roster', 
    component: FantasyRosterIndex, 
    name: 'fantasy.rosters.index',
    meta: {
      title: 'Roster'
    }
  },
  //Fantasy Trades
  { 
    path: '/leagues/:leagueId/fantasy/:fantasySeasonId/trades', 
    component: FantasyTradeIndex, 
    name: 'fantasy.trades.index',
    meta: {
      title: 'Trades'
    }
  },
  { 
    path: '/leagues/:leagueId/fantasy/:fantasySeasonId/trades/create', 
    component: FantasyTradeCreate, 
    name: 'fantasy.trades.create',
    meta: {
      title: 'Propose Trade'
    }
  },
  //Fantasy Free Agents
  { 
    path: '/leagues/:leagueId/fantasy/:fantasySeasonId/free-agents', 
    component: FantasyFreeAgentIndex, 
    name: 'fantasy.freeagents.index',
    meta: {
      title: 'Free Agents'
    }
  },
  //Fantasy Active Fighters
  { 
    path: '/leagues/:leagueId/fantasy/active-fighters', 
    component: FantasyActiveFighterIndex, 
    name: 'fantasy.activefighters.index',
    meta: {
      title: 'Active Fighters'
    }
  },
  //Fantasy Leaderboard
  { 
    path: '/leagues/:leagueId/leaderboard/fantasy/:fantasySeasonId', 
    component: LeaderboardIndex, 
    name: 'fantasy.leaderboards.show',
    meta: {
      title: 'Fantasy Leaderboard'
    }
  },
  //Leagues
  { 
    path: '/leagues', 
    component: LeagueIndex, 
    name: 'leagues.index',
    meta: {
      title: 'Leagues'
    },
    beforeEnter: async (to, from) => {
      if(user.value.leagues.length === 1) {
        return { name: 'leagues.upcoming.show', params: { leagueId: user.value.leagues[0].id } }
      }

      if(user.value.leagues.length === 0) {
        return { name: 'leagues.create' }
      }

    },
  },
  { 
    path: '/leagues/create', 
    component: LeagueCreate, 
    name: 'leagues.create',
    meta: {
      title: 'Create League'
    }
  },
  { 
    path: '/leagues/:leagueId/edit', 
    component: LeagueEdit, 
    name: 'leagues.edit',
    meta: {
      title: 'Edit League'
    }
  },
  { 
    path: '/leagues/:leagueId/upcoming', 
    component: LeagueShow, 
    name: 'leagues.upcoming.show',
    meta: {
      title: 'Upcoming Events'
    }
  },
  { 
    path: '/leagues/:leagueId/previous', 
    component: LeagueShow, 
    name: 'leagues.previous.show',
    meta: {
      title: 'Previous Events'
    }
  },
  //League Leaderboard
  { 
    path: '/leagues/:leagueId/leaderboard', 
    name: 'leaderboard.index',
    beforeEnter: async (to) => {
      await fetch(route(to.name, Object.values(to.params)));
      if(data.value.routeName === 'leaderboard.global.index') return { name: data.value.routeName, params: { leagueId: data.value.league.id } }
      else return { name: data.value.routeName, params: { leagueId: data.value.league.id, eventId: data.value.eventId } }
    }
  },
  { 
    path: '/leagues/:leagueId/leaderboard/events/:eventId', 
    component: LeaderboardIndex, 
    name: 'leaderboard.show',
    meta: {
      title: 'Event Leaderboard'
    },
  },
  { 
    path: '/leagues/:leagueId/leaderboard/year/:year', 
    component: LeaderboardIndex, 
    name: 'leaderboard.year.index',
    meta: {
      title: 'Annual Leaderboard'
    }
  },
  //Global Leaderboard
  { 
    path: '/leagues/:leagueId/leaderboard/global', 
    name: 'leaderboard.global.index',
    beforeEnter: async (to) => {
      await fetch(route(to.name, Object.values(to.params)));
      return { name: data.value.routeName, params: { leagueId: data.value.league.id, eventId: data.value.eventId } }
    }
  },
  { 
    path: '/leagues/:leagueId/leaderboard/events/:eventId/global', 
    component: LeaderboardIndex, 
    name: 'leaderboard.global.show',
    meta: {
      title: 'Event Leaderboard'
    }
  },
  { 
    path: '/leagues/:leagueId/leaderboard/year/:year/global', 
    component: LeaderboardIndex, 
    name: 'leaderboard.global.year.index',
    meta: {
      title: 'Annual Leaderboard'
    }
  },
  { 
    path: '/leagues/:leagueId/leaderboard/year/:year/global/:promotion', 
    component: LeaderboardIndex, 
    name: 'leaderboard.global.year.show',
    meta: {
      title: 'Promotion Leaderboard'
    }
  },
  //League vs league leaderboard
  { 
    path: '/leagues/:leagueId/leaderboard/events/:eventId/league-vs-league', 
    component: LeaderboardLeagueVsLeagueShow, 
    name: 'leaderboard.leaguevsleague.show',
    meta: {
      title: 'Event Leaderboard'
    }
  },
  { 
    path: '/leagues/:leagueId/leaderboard/year/:year/league-vs-league', 
    component: LeaderboardLeagueVsLeagueShow, 
    name: 'leaderboard.leaguevsleague.year.index',
    meta: {
      title: 'Annual Leaderboard'
    }
  },
  { 
    path: '/leagues/:leagueId/leaderboard/year/:year/league-vs-league/:promotion', 
    component: LeaderboardLeagueVsLeagueShow, 
    name: 'leaderboard.leaguevsleague.year.show',
    meta: {
      title: 'Promotion Leaderboard'
    }
  },
  //League users
  { 
    path: '/leagues/:leagueId/users', 
    component: LeagueUserIndex, 
    name: 'leagues.users.index',
    meta: {
      title: 'League Users'
    }
  },
  //League leave
  { 
    path: '/leagues/:leagueId/leave/edit', 
    component: LeagueLeaveEdit, 
    name: 'leagues.leave.edit',
    meta: {
      title: 'Leave League'
    }
  },
  //Seasons
  { 
    path: '/leagues/:leagueId/leaderboard/season/:seasonId', 
    component: LeaderboardIndex, 
    name: 'leagues.seasons.show',
    meta: {
      title: 'Season Leaderboard'
    }
  },
  //Invites
  { 
    path: '/leagues/:leagueId/invite/create', 
    component: InviteCreate, 
    name: 'invites.create',
    meta: {
      title: 'Invite Users'
    }
  },
  { 
    path: '/invites/:leagueSlug', 
    component: InviteEdit, 
    name: 'invites.edit',
    meta: {
      title: 'Accept Invite'
    }
  },
  //Picks
  { 
    path: '/leagues/:leagueId/events/:eventId/picks', 
    component: PickIndex, 
    name: 'picks.index',
    meta: {
      title: 'Picks'
    }
  },
  //MyFighter
  { 
    path: '/leagues/:leagueId/fighter/edit', 
    component: MyFighterEdit, 
    name: 'fighter.edit',
    meta: {
      title: 'Customize My Fighter'
    }
  },
  //Send GIF
  { 
    path: '/leagues/:leagueId/send-gif', 
    component: SendGIFEdit, 
    name: 'send-gif.edit',
    meta: {
      title: 'Send a GIF'
    }
  },
  //Admin
  { 
    path: '/admin', 
    component: AdminIndex, 
    name: 'admin.index',
    meta: {
      title: 'Admin'
    }
  },
  { 
    path: '/events/create', 
    component: EventCreate, 
    name: 'admin.events.create',
    meta: {
      title: 'Create Event'
    }
  },
  { 
    path: '/events/:eventId/edit', 
    component: EventEdit, 
    name: 'admin.events.edit',
    meta: {
      title: 'Edit Event'
    }
  },
  { 
    path: '/admin/predict', 
    component: AdminUFCStatsCreate, 
    name: 'admin.ufcstats.create',
    meta: {
      title: 'Get Prediction Data'
    }
  },
  { 
    path: '/admin/sync', 
    component: AdminSyncEdit, 
    name: 'admin.sync.edit',
    meta: {
      title: 'Sync Events'
    }
  },
  { 
    path: '/admin/orphaned-fighters', 
    component: AdminOrphanedFighterIndex, 
    name: 'admin.orphanedfighters.index',
    meta: {
      title: 'Orphaned fighters'
    }
  },
  { 
    path: '/403', 
    component: Error403, 
    name: 'errors.403',
    meta: {
      title: 'Unauthorized'
    }
  },
  { 
    path: '/500', 
    component: Error500, 
    name: 'errors.500',
    meta: {
      title: 'Server Error'
    }
  },
  { 
    path: '/503', 
    component: Error503, 
    name: 'errors.503',
    meta: {
      title: 'You\'re Offline'
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: Error404, 
    name: 'errors.404',
    meta: {
      title: 'Page Not Found'
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

const { onlineAndConnected } = usePwa()

router.beforeEach(async (to) => {

  //If user is offline send to offline page
  if(!onlineAndConnected.value && to.name !== 'errors.503') return { name: 'errors.503' }

  //redirect if not logged in and route is not a guest route
  await attempt();
  if(!authenticated.value && !redirect.value && !guestOnlyRoutes.includes(to.name) && !guestOrAuthRoutes.includes(to.name)) setRedirect(to.path) 
  if(authenticated.value && redirect.value){
    let path = redirect.value
    setRedirect(null)
    return { path: path }
  }
  if(!authenticated.value && !guestOnlyRoutes.includes(to.name) && !guestOrAuthRoutes.includes(to.name)) return { name: 'login.create' }
  if(authenticated.value && guestOnlyRoutes.includes(to.name)) return { name: 'leagues.index' }
  if(authenticated.value && user.value.id !== 1 && to.name.includes('admin.')) return { name: 'errors.403' }

  //Page title
  const title = to.meta.title
  const titleFromParams = to.params.pageTitle;
  
  if(title){
    document.title = title + titleTail
  }
  if(titleFromParams){
    document.title = titleFromParams + titleTail
  }

});

router.onError((error, to) => {
  if(error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Failed to load module script')){
    if (!to?.fullPath) window.location.reload()
    else window.location = to.fullPath
  }
})

export default router