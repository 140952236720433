<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <g>
        <g>
            <g>
                <path d="M511.915,266.261c-0.021-0.576-0.235-1.131-0.341-1.707c-0.171-0.832-0.299-1.664-0.661-2.411
                c-0.043-0.085-0.021-0.192-0.064-0.277L489.515,219.2c-1.813-3.584-5.504-5.867-9.536-5.867H362.667v-96
                c0-0.171-0.085-0.299-0.085-0.448c-0.021-0.427-0.192-0.832-0.256-1.259c-0.171-1.003-0.341-1.963-0.768-2.859
                c-0.021-0.064-0.021-0.128-0.043-0.192l-21.333-42.667c-1.835-3.627-5.525-5.909-9.557-5.909H181.291
                c-4.032,0-7.723,2.283-9.536,5.888l-21.333,42.667c-0.064,0.128-0.043,0.277-0.107,0.405c-0.213,0.469-0.256,1.003-0.405,1.493
                c-0.256,0.875-0.512,1.749-0.533,2.645c0,0.085-0.043,0.149-0.043,0.235V192H32c-4.032,0-7.723,2.283-9.536,5.888L1.131,240.555
                c-0.043,0.107-0.043,0.213-0.085,0.32c-0.299,0.661-0.405,1.387-0.576,2.112c-0.149,0.661-0.384,1.301-0.405,1.984
                c0,0.128-0.064,0.235-0.064,0.363v192C0,443.221,4.779,448,10.667,448h490.667c5.888,0,10.667-4.779,10.667-10.667V266.667
                C512,266.517,511.915,266.389,511.915,266.261z M362.667,234.667h110.72L484.053,256H362.667V234.667z M187.904,85.333h136.149
                l10.667,21.333H177.237L187.904,85.333z M38.592,213.333h110.741v21.333H27.925L38.592,213.333z M490.667,426.667H21.333V256H160
                c5.888,0,10.667-4.779,10.667-10.667V128h170.667v95.893c0,0.043-0.021,0.064-0.021,0.107s0.021,0.064,0.021,0.085v42.581
                c0,5.888,4.779,10.667,10.667,10.667h138.667V426.667z"/>
                <path d="M270.763,171.456c-3.989-1.664-8.576-0.768-11.627,2.304l-21.333,21.333c-4.16,4.16-4.16,10.923,0,15.083
                c4.16,4.16,10.923,4.16,15.083,0l3.115-3.115V288c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.688-10.667V181.333
                C277.355,177.003,274.752,173.12,270.763,171.456z"/>
                <path d="M426.667,384c-5.888,0-10.667-4.8-10.667-10.667c0-5.888-4.779-10.667-10.667-10.667
                c-5.888,0-10.667,4.779-10.667,10.667c0,17.643,14.357,32,32,32s32-14.357,32-32c0-8.192-3.093-15.659-8.171-21.333
                c5.077-5.675,8.171-13.141,8.171-21.333c0-17.643-14.357-32-32-32s-32,14.357-32,32c0,5.888,4.779,10.667,10.667,10.667
                c5.888,0,10.667-4.779,10.667-10.667C416,324.8,420.779,320,426.667,320s10.667,4.8,10.667,10.667
                c0,5.867-4.779,10.667-10.667,10.667S416,346.112,416,352c0,5.888,4.779,10.667,10.667,10.667s10.667,4.8,10.667,10.667
                C437.333,379.179,432.555,384,426.667,384z"/>
                <path d="M117.333,309.291c0-17.643-14.357-32-32-32c-17.643,0-32,14.357-32,32c0,5.888,4.779,10.667,10.667,10.667
                s10.667-4.779,10.667-10.667c0-5.867,4.779-10.667,10.667-10.667S96,303.424,96,309.291c0,10.901-4.267,21.163-11.968,28.885
                L56.448,365.76c-3.051,3.051-3.968,7.637-2.304,11.627C55.787,381.397,59.691,384,64,384h42.667
                c5.888,0,10.667-4.8,10.667-10.688c0-5.888-4.779-10.667-10.667-10.667H89.749l9.365-9.365
                C110.869,341.504,117.333,325.888,117.333,309.291z"/>
            </g>
        </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    </svg>
</template>