<script setup>
	import authService from '@/Services/authService.js'
    const { user } = authService()
</script>

<template>
    <div :class="[ user ? user.theme : 'default' ]" class="relative w-full h-screen bg-surface-1">
        <div class="absolute left-1/2 -translate-x-1/2 -translate-y-1/2" style="top: calc(50% - 3.5rem);">
            <div
            class="text-content-1 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
                <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
            </div>
        </div>
    </div>
</template>