import { reactive, computed } from 'vue'

const state = reactive({
    cookiesAcceptedAt: null,
})

export default function useCookieBanner(){
    const cookiesAcceptedAt = computed(() => state.cookiesAcceptedAt)

    let init = function(){
        if(typeof(Storage) !== 'undefined' && localStorage.getItem('cookiesAcceptedAt')) setCookiesAcceptedAt(localStorage.getItem('cookiesAcceptedAt'))
        else if(getCookie('cookiesAcceptedAt') !== '') setCookiesAcceptedAt(getCookie('cookiesAcceptedAt'))
    }

    let setCookiesAcceptedAt = function(value){
        state.cookiesAcceptedAt = value
        
        if(typeof(Storage) !== 'undefined') localStorage.setItem('cookiesAcceptedAt', value)
		else document.cookie = 'cookiesAcceptedAt='+value+'; expires=Thu, 18 Dec 2038 12:00:00 UTC; path=/'
    }

    let getCookie = function(cname) {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      }

    return {
        init,
        cookiesAcceptedAt,
        setCookiesAcceptedAt
    }
}