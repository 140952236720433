<script setup>
    import { useRouter } from 'vue-router'
    import notificationsStore from '@/Stores/notificationsStore'
    import authService from '@/Services/authService'

    const { user } = authService()
    const props = defineProps({
        league: Object,
        notification: Object,
        index: Number,
    });
    const emit = defineEmits(['close']);
    const router = useRouter()

    let redirect = async function() {
        await notificationsStore.close(props.index);
        if(notificationsStore.items.length === 0){
            notificationsStore.show_notifications = false;
        }
        router.push({ name: 'invites.create', params: { leagueId: props.league.id }})
    }

</script>

<template>
    <div>
        <h2 class="text-3xl mb-2">Welcome, <span class="font-bold">{{ user.name }}</span> 👋</h2>
        <p class="mb-2">Watch this video for a quick overview (47 seconds).</p>
        <div class="mb-2">
            <iframe class="w-full aspect-video" src="https://www.youtube.com/embed/tr86WJFiaR8?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <p v-if="league.user_invite">You can invite others to join your league, or close and start making your picks.</p>
        <div class="flex items-center justify-center mt-3">
            <a v-if="league.user_invite" href="#" @click.prevent="redirect" class="font-semibold py-1 px-2 bg-accent-1 text-content-2 inline-block">
                Invite friends
            </a>
            <a href="#" :class="{ 'font-semibold bg-accent-1 text-content-2' : !league.user_invite, 'text-content-1': league.user_invite }" class="py-1 px-2 inline-block" @click="emit('close')">
                Close
            </a>
        </div>
    </div>
</template>