<script setup>

    import { onMounted, onUnmounted } from 'vue';

    const emit = defineEmits(['close']);

    onMounted(() => {
        document.body.style.overflowY = 'hidden';
    });

    onUnmounted(() => {
        document.body.style.overflowY = 'auto';
    });

</script>

<template>
    <div class="fixed w-full h-screen z-50 bg-black/75 left-0 top-0" @click="emit('close')">
        <slot />
    </div>
</template>